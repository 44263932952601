/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';

import { APP_CONFIG } from '../../constants/environment.constants';
import { useGlobalData } from '../useGlobalData/useGlobalData';

import { TrackerIdentification } from '@aircall/tracker';
import { tracker } from '@constants/tracker.constants';
import { getBrowser } from '@dashboard/library';
import { AgentRole } from '@generated/globalTypes';
import { getCommonPayload, getSignedInAsPayload } from '@helpers/tracker.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import Cookies from 'js-cookie';

/**
 * This code will be removed after some time.
 * It removes Rudderstack v3 cookies from the browser,
 * which came from aircall.io. These cookies are not
 * set anymore, as Rudderstack has been configured in
 * aircall-website project to use v2 cookies. This was
 * creating a issue that caused millions of Datadog logs.
 *
 * More info in DAT-130 Jira ticket
 */
/* istanbul ignore next */
function removeRudderstackV3Cookies() {
  const cookies = Cookies.get() ?? {};

  Object.entries(cookies).forEach(([name, value]) => {
    if (value.includes('RS_ENC_v3')) {
      Cookies.remove(name, { domain: '.aircall.io', path: '/' });
    }
  });
}

/**
 * Hook to manage the tracker initialization and updates given the current user.
 */
export function useInitTracker(): void {
  const { currentCompany, currentUser, isTrial } = useGlobalData();
  const { disableFrontendTracking } = useFeaturesFlags();

  useEffect(() => {
    // Skip all tracking logic if frontend tracking is disabled
    /* istanbul ignore next */
    if (disableFrontendTracking) {
      return;
    }

    // Check if essential data is available
    /* istanbul ignore next */
    if (!currentUser || !currentCompany) {
      return;
    }

    removeRudderstackV3Cookies();

    // Initializes the tracker
    try {
      tracker.init();
    } catch (e) {
      /* istanbul ignore next */
      console.error('error thrown at initialization of Rudderstack', e);
    }

    // Updates the user info for the tracker once we got it
    try {
      tracker.identify({
        ...getCommonPayload(),
        ...getSignedInAsPayload(),
        device_details: getBrowser(),
        user_id: parseInt(currentUser.ID),
        company_id: currentCompany.id,
        company_name: currentCompany.name,
        company_plan: currentCompany.plan,
        language: currentUser.language.replace('-', '_'),
        company: {
          billing_period: currentCompany.billingPeriod,
          country: currentCompany.address?.country as string,
          created_at: currentCompany.createdAt,
          last_plan_change: currentCompany.lastTimePlanChanged,
          is_trial: isTrial,
          tier: currentCompany.tierLevel,
        },
        device: APP_CONFIG.device,
        email: currentUser.email,
        environment: APP_CONFIG.environment,
        is_admin: currentUser.role === AgentRole.admin,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        // do type casting here because we don't have `created_at` in currentUser
        // but it is required by TrackerIdentification
      } as TrackerIdentification);
    } catch (e) {
      /* istanbul ignore next */
      console.error('Error thrown during tracker identification:', e);
    }
  }, [disableFrontendTracking, currentUser, currentCompany, isTrial]);
}
